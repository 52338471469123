.question__input_yes,
.question__input_no,
.input_checkbox {
  width: 19px;
  height: 19px;
}
.label_question {
  font-size: 1.2em;
}

span {
  font-size: 1.5em;
}

tr,
td {
  padding: 10px;
}

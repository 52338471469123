.content {
  margin: 20px;
  width: 70%;
  text-align: center;
  background: #7996f0;
  padding: 15px;
  border-radius: 10px;
  color: black;
}

.content .dataUser {
  text-align: left;
}

.content .test1__next,
.content .content__button,
.content .test1__finish,
.test__two .content_button {
  float: right;
  text-decoration: none;
  display: inline-block;
  color: white;
  padding: 10px 20px;
  margin: 10px 20px;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-image: linear-gradient(
    to right,
    #9eefe1 0%,
    #4830f0 51%,
    #9eefe1 100%
  );
  background-size: 200% auto;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
  transition: 0.5s;
}

.content .test1__next:hover,
.content .content__button:hover,
.test__two .content_button:hover,
.content .test1__finish:hover {
  background-position: right center;
}
.content .test1__finish {
  float: none;
}

p {
  text-align: justify;
}

.test1__next {
  display: block;
}

.dataUser {
  padding-left: 40px;
}
.datauser_age {
  margin-right: 15px;
}

table {
  border-collapse: collapse;
}
tr,
td {
  text-align: left;
  width: 80%;
  padding: 10px; /* Поля вокруг содержимого таблицы */
  border: 1px solid rgb(78, 73, 73); /* Параметры рамки */
}
label {
  padding-right: 15px;
}
.label_tel {
  margin-right: 15px;
}
.wrap_analiz {
  text-align: center;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer h5 {
    display: inline-block;
    padding: 2%;
}

.footer button {
    text-decoration: none;
    display: inline-block;
    color: white;
    padding: 10px 20px;
    margin: 10px 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-image: linear-gradient(
        to right,
        #9eefe1 0%,
        #4830f0 51%,
        #9eefe1 100%
    );
    background-size: 200% auto;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
    transition: 0.5s;
}

.footer button:hover {
    background-position: right center;
}

.footer h5 {
    color: rgb(241, 73, 73);
}
